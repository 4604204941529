@import 'assets/styles/_containers.scss';

.root {
  background: $color-product-slider-blue;
  padding-top: 2.5rem;
  padding-bottom: 1.875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.container {
  @include container;
}

.header {
  @include h1;
  text-transform: uppercase;
  color: $color-header-blue;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2.75rem;
}

.slider-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}

.card-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  height: 100%;
}
