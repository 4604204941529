@import 'assets/styles/_variables.scss';

.purchase-header {
  @include h3;
  color: $color-header-blue;
  text-transform: uppercase;
  margin-bottom: 0.9375rem;
  margin-top: 0;
}

.price-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.price {
  margin-left: auto;
  font-size: 1.125rem;
  text-align: right;
}
.oldprice,
.newprice {
  display: block;
}
.newprice {
  font-weight: bold;
}
.oldprice {
  font-size: 1rem;
  margin-top: 3px;
}
.newprice::before {
  content: 'New Price: ';
}
.oldprice::before {
  content: 'Old Price: ';
}
.price .oldprice:only-child::before,
.price .newprice:only-child::before {
  content: '';
}

.toolow {
  display: block;
  color: #ff2a00;
  font-size: 14px;
  margin-bottom: 15px;
}
