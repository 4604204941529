@import 'assets/styles/_breakpoints.scss';
@import 'assets/styles/_containers.scss';

.root {
  @include container;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  @include breakpoint(sm, min) {
    padding: 0;
  }
}

.collection-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.375rem 1.25rem 1.875rem 1.25rem;
  background-color: $color-white;
  border-top: 10px solid;
  width: 100%;
  box-sizing: border-box;
}

.title {
  @include h2;
  color: $color-header-blue;
  text-transform: uppercase;
  margin: 0;
}

.collection-image-wrap {
  position: relative;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;

  &:before {
    display: block;
    content: '';
    padding-top: 100%;
  }
}

.btn {
  display: block;
  text-decoration: none;
  background-color: $color-header-blue;
  color: $color-white;
  text-transform: uppercase;
  font-size: 0.8125rem;
  line-height: 1.4;
  padding: 0.75rem;
  font-weight: $font-weight-bold;
  width: 90%;
  max-width: 250px;
  border-radius: 0.3125rem;
  text-align: center;
  @include noSelect;
}

.link-title {
  display: none;

  @include breakpoint(lg, min) {
    display: inline;
  }
}
