@import 'assets/styles/_variables.scss';

.option-name {
  color: $color-header-blue;
  font-weight: $font-weight-bold;
  margin-bottom: 0.3125rem;
}

.option-select {
  margin-bottom: 0.9375rem;
}
