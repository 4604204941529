@import 'assets/styles/_variables.scss';

.description-header {
  @include h3;
  color: $color-header-blue;
  text-transform: uppercase;
  margin-bottom: 0.9375rem;
  margin-top: 0;
}

.description-text {
  @include paragraph;
  color: $color-content;
  margin-bottom: 0.9375rem;

  /* Styles the Content Pulled in From Shopify */
  ul {
    padding: 0 0 0 1.25rem;
  }
}

.specs {
  margin: 0;
  padding: 0 0 0 1.25rem;
}

.spec {
  margin-bottom: 0.3125rem;
}
