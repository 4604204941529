@import 'assets/styles/_variables.scss';

.purchase-btn, .special-btn {
  display: block;
  font-family: $font-family-futura;
  font-weight: $font-weight-bold;
  font-size: 0.8125rem;
  line-height: 1.3;
  border-radius: 0.3125rem;
  border: none;
  padding: 0.875rem 2rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  color: $color-header-blue;
  text-decoration: none;
  margin-bottom: 0.625rem;
  @include noSelect;
}

.add-to-cart,
.not-available-online {
  background: $color-yellow;
}

.special-btn {
  background: $color-light-grey;
  color: $color-header-blue;
  padding: 0.875rem;
}
.white-btn {
  background: #FFF;
}

.special-btn:last-child {
  margin-bottom: 0;
}

@mixin gray-btn {
  margin-bottom: 0;
  display: none;
  &:nth-child(1), &:nth-child(2) {
    display: block;
  }
}

.gray-btn {
  margin-bottom: 0;
  span {
    display: none;
  }
}


.beat-competitor {
  font-size: 0.775rem;
}

.inactive-cart {
  opacity: 0.5;
  pointer-events: none;
  @include noSelect;
}

.variantId, .checkoutId {
  display: none;
}
