@use './components/product/ProductAddToCart/ProductAddToCart.module.scss' as addToCart;

@import 'assets/styles/_breakpoints.scss';
@import 'assets/styles/_containers.scss';

.root {
  padding: 30px 20px 20px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.18);
  text-decoration: none;
  box-sizing: border-box;

  .link {
    text-decoration: none;
    display: flex;
    align-content: left;
    flex-direction: column;
    margin-top: auto;
    height: 100%;
    justify-content: space-between;
    a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}

.name {
  @include h3;
  color: $color-header-blue;
  text-transform: uppercase;
  margin: 0;
}

.product-image-wrap {
  position: relative;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;

  &:before {
    display: block;
    content: '';
    padding-top: 100%;
  }
}

.cart-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.625rem;

  @include breakpoint(xl, 'min') {
    flex-wrap: nowrap;
  }

  .price {
    color: $color-dark-grey;
    font-weight: $font-weight-bold;
    font-size: 1.125rem;
    line-height: 1.44;
    margin-bottom: 0.875rem;

    @include breakpoint(xl, 'min') {
      margin-bottom: 0;
    }
    span {
      display: block;
      color: #999;
      font-weight: normal;
      font-size: 1rem;
    }
  }
}

.add-to-cart-wrap {
  width: 100%;

  @include breakpoint(xl, 'min') {
    width: auto;
    margin-left: auto;
  }
}

.see-options {
  display: block;
  font-family: $font-family-futura;
  font-weight: $font-weight-bold;
  background: $color-yellow;
  font-size: 0.8125rem;
  line-height: 1.3;
  border-radius: 0.3125rem;
  border: none;
  padding: 0.875rem 2rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  color: $color-header-blue;
  text-decoration: none;
  margin-bottom: 0.625rem;
  @include noSelect;
}

.toolow {
  color: #ff2a00;
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
  display: block;
}
