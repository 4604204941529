@import 'assets/styles/_variables.scss';

.manufacturer {
  margin-top: 1.5625rem;
}

.manufacturer-link {
  text-decoration: none;
  font-family: $font-family-futura;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.43;
  display: block;
  text-transform: uppercase;
  color: $color-header-blue;
}

.contact-link {
  text-decoration: none;
  color: $color-header-blue;
}
