@import 'assets/styles/_breakpoints.scss';
@import 'assets/styles/_containers.scss';

.root {
  @include container;
  box-sizing: border-box;
  padding-left: 1.875rem;
  padding-right: 1.875rem;

  @include breakpoint(md, min) {
    padding-left: 0;
    padding-right: 0;
  }
}

.name-wrap {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.25rem;

  .name {
    color: $color-header-blue;
    text-align: center;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    @include h1;
    @include breakpoint(lg, min) {
      margin-bottom: 1.75rem;
    }
  }
}

.image-wrap {
  position: relative;
  background-color: $color-white;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  iframe,
  video {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    height: 100%;
  }
}

.pricematch {
    display: block;
    text-align: center;
    color: #1a61a8;
    background: #FFF;
    text-decoration: none;
    font-weight: 700;
    font-size: 0.8125rem;
    line-height: 1.3;
    border-radius: 0.3125rem;
    padding: 14px 28px;
    cursor: pointer;
    text-transform: uppercase;
}

.bestprice {
  display: block;
  color: #1a61a8;
  text-align: center;
  margin-top: 0.625rem;
  font-size: 0.9rem;
}
